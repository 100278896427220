
html {
    font-size: 62.5%;
    font-family: "Kanit", sans-serif !important;
    /* background-color: #121212; */
}

.kanit-thin {
    font-family: "Kanit", sans-serif;
    font-weight: 100;
    font-style: normal;
}

.kanit-extralight {
    font-family: "Kanit", sans-serif;
    font-weight: 200;
    font-style: normal;
}

.kanit-light {
    font-family: "Kanit", sans-serif;
    font-weight: 300;
    font-style: normal;
}

.kanit-regular {
    font-family: "Kanit", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.kanit-medium {
    font-family: "Kanit", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.kanit-semibold {
    font-family: "Kanit", sans-serif !important;
    font-weight: 600;
    font-style: normal;
}

.kanit-bold {
    font-family: "Kanit", sans-serif  !important;
    font-weight: 700;
    font-style: normal;
}

.kanit-extrabold {
    font-family: "Kanit", sans-serif;
    font-weight: 800;
    font-style: normal;
}

.kanit-black {
    font-family: "Kanit", sans-serif;
    font-weight: 900;
    font-style: normal;
}

.kanit-thin-italic {
    font-family: "Kanit", sans-serif;
    font-weight: 100;
    font-style: italic;
}

.kanit-extralight-italic {
    font-family: "Kanit", sans-serif;
    font-weight: 200;
    font-style: italic;
}

.kanit-light-italic {
    font-family: "Kanit", sans-serif;
    font-weight: 300;
    font-style: italic;
}

.kanit-regular-italic {
    font-family: "Kanit", sans-serif;
    font-weight: 400;
    font-style: italic;
}

.kanit-medium-italic {
    font-family: "Kanit", sans-serif;
    font-weight: 500;
    font-style: italic;
}

.kanit-semibold-italic {
    font-family: "Kanit", sans-serif;
    font-weight: 600;
    font-style: italic;
}

.kanit-bold-italic {
    font-family: "Kanit", sans-serif;
    font-weight: 700;
    font-style: italic;
}

.kanit-extrabold-italic {
    font-family: "Kanit", sans-serif;
    font-weight: 800;
    font-style: italic;
}

.kanit-black-italic {
    font-family: "Kanit", sans-serif;
    font-weight: 900;
    font-style: italic;
}


.flex{
    display: flex;
}

.justify-content-center{
    justify-content: center;
}
   

.p-4{
    padding: 4px;
}

.p-6{
    padding: 6px;
}

.p-8{
    padding: 8px;
}

.p-16{
    padding: 16px;
}
.pt-4{
    padding-top: 4px;
}

.pt-8{
    padding-top: 8px;
}

.pt-16{
    padding-top: 16px;
}

.ptlr-8{
    padding: 8px 8px 0px 8px
}

.plr-2{
    padding-left:  2px;
    padding-right: 2px;
}

.plr-4{
    padding-left:  4px;
    padding-right: 4px;
}

.plr-8{
    padding-left:  8px;
    padding-right: 8px;
}

.plr-16{
    padding-left:  16px;
    padding-right: 16px;
}

.plr-24{
    padding-left:  24px;
    padding-right: 24px;
}

.plr-32{
    padding-left:  32px;
    padding-right: 32px;
}

.plr-40{
    padding-left:  40px;
    padding-right: 40px;
}

.plr-48{
    padding-left:  48px;
    padding-right: 48px;
}

.text-align-center{
    text-align: center;
}

.font-item-title{
    font-size: 10px !important; 
    line-height: 14.95px !important; 
    font-weight: 400 !important;
    font-family: "Kanit", sans-serif !important;
}

.badge-total{
    background-color: #0074EC;
   width: 48px;
   height: 22px;
   border-radius: 3px;
}

.font-header{
    font-size: 24px !important;
    color: #000000 !important;
    font-weight: 600 !important;
    white-space: nowrap;
    font-family: "Kanit", sans-serif !important;
}

.font-subheader{
    font-size: 16px !important;
    color: #000000 !important;
    font-weight: 400 !important;
    white-space: nowrap;
    font-family: "Kanit", sans-serif !important;
}

.font-item-count{
    font-size: 22px !important;
    line-height: 32.89px !important;
    font-weight: 500 !important;
    color: #FFFFFF !important;
    font-family: "Kanit", sans-serif !important;
}

.font-item-sold{
    font-size: 22px !important;
    color: #4A4A4A !important;
    font-weight: 500 !important;
    white-space: nowrap;
    font-family: "Kanit", sans-serif !important;
}

.font-item-sold2{
    font-size: 9px !important;
    color: #000000 !important;
    font-weight: 500 !important;
    white-space: nowrap;
    font-family: "Kanit", sans-serif !important;
}

.font-list{
    font-family: "Kanit", sans-serif !important;
    font-weight: 500 !important;
    white-space: nowrap;
    font-size: 20px !important;

}

.font-payment{
    font-size: 20px !important;
    color: #000000 !important;
    font-weight: 500 !important;
    white-space: nowrap;
    font-family: "Kanit", sans-serif !important;
}

.font-payment-location{
    font-size: 24px !important;
    color: #000000 !important;
    font-weight: 500 !important;
    white-space: nowrap;
    font-family: "Kanit", sans-serif !important;
}

.img-prompay{
    width: 189px;
    height: 59px;
   
}

.border-prompay{
    border: 2px solid #003D6A;
    border-radius: 5px;
    padding: 0 30px;
}


.border-prompayQr{
    border: 2px solid #003D6A;
}

.img-visa{
    width: 64px;
    height: 54px;
}

.img-mastercard{
    width: 60px;
    height: 36px;
}

.img-jcb{
    width: 49px;
    height: 38px;
}

.img-union{
    width: 46px;
    height: 29px;
}


.custom-gallery {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  
  .custom-gallery .image-gallery {
    display: flex;
    overflow: hidden;
  }
  
  .custom-gallery .image-gallery-slide img {
    width: calc(100% - 2px);
    height: 33vh; 
}


@media (min-width: 600px) {
    .custom-gallery .image-gallery-slide img {
        height: 45vh; 
    }
}

@media (min-width: 820px) {
    .custom-gallery .image-gallery-slide img {
        height: 45vh; 
    }
}

@media (min-width: 1024px) {
    .custom-gallery .image-gallery-slide img {
        height: 45vh; 
    }
}

@media (min-width: 1366px) {
    .custom-gallery .image-gallery-slide img {
        height: 45vh; 
    }
}



  
  .custom-gallery .image-gallery-bullets {
    position: absolute !important;
    bottom: -20px !important; 
    left: 50% !important;
    transform: translateX(-50%) !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border: none;
    box-shadow: none;
    transform: none;
  }
  
  .custom-gallery .image-gallery-bullets .image-gallery-bullet {
    margin: 0 5px;
    width: 10px;
    height: 10px;
    background: #CDCDCD; 
    border-radius: 50%;
    border: none;
    transition: background 0.3s ease;
    box-shadow: none;
    transform: none;
  }
  
  .custom-gallery .image-gallery-bullets .image-gallery-bullet.active {
    background: #EFEFEF; 
    border: none;
    box-shadow: none;
    transform: none;
  }
  
  .custom-gallery .image-gallery-bullets .image-gallery-bullet:hover {
    background: #EFEFEF;  
    border: none;
    box-shadow: none;
    transform: none;
  }
  
  @media (min-width: 1366px) {
    .font-list{
        padding-left: 128px;
    }
    .select-center {
        justify-content: center;
    }
    .pl-128{
        padding-left: 128px !important;
    }
  
}


@media (min-width: 667px) {
    .select-center {
        justify-content: center;
    }
}

.m-maintenance {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 80% !important;
    max-width: 400px !important; 
    height: auto !important;
    padding: 16px !important;
    background-color: #ffffff !important;
    text-align: center !important;
    border-radius: 16px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important; 
  }
  
  .font-maintenance {
    font-size: 24px !important;
    color: #000000 !important;
    font-weight: 500 !important;
    font-family: "Kanit", sans-serif !important;
    white-space: nowrap !important;
  }
  
  .font-maintenance-sub {
    font-size: 16px !important;
    color: #000000 !important;
    font-weight: 500 !important;
    font-family: "Kanit", sans-serif !important;
    /* margin-top: 2px !important; */
     /* line-height: 1.5 !important;  */
  }
  
  .maintenance-btn {
    padding: 12px 24px !important;
    background: #0074ec !important;
    border-radius: 11px !important;
    width: 100% !important;
    max-width: 159px !important;
    height: 36px !important;
    font-size: 22px !important;
    font-family: "Kanit", sans-serif !important;
    font-weight: 500 !important;
    color: #ffffff !important;
    text-transform: none !important;
    margin: 0 auto !important; 
  }
  
  .content-m {
    /* flex-grow: 1 !important;  */
    margin: 8px 0 !important; 
  }
  
  /* Media Queries */
  @media (max-width: 600px) {
    .m-maintenance {
      width: 80% !important;
      max-width: 360px !important;
      padding: 12px !important;
    }
  
    .font-maintenance {
      font-size: 20px !important;
    }
  
    .font-maintenance-sub {
      font-size: 14px !important;
    }
  
    .maintenance-btn {
      font-size: 18px !important;
      height: 32px !important;
    }
  }
  
